@import '../../custom.scss';

.logo {
    width: 60px;
    height: 50px;
    background-image: url('../../assets/images/newlogo.png');
    background-repeat: no-repeat;
    background-size: 100%;
}

.aboutMe {
    padding-right: 32px;
    color: #009aaa;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    cursor: pointer;
}

.projects {
    color: $white;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    cursor: pointer;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('../../assets/images/menu2.png');
}