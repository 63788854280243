@import '../../../../custom.scss';

.projectsListWrapper {
    padding: 32px 0px;

    .projectsListCloseButtonContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 8px;
    }

    .myswiperWrapper {
        width: 100%;
        height: auto;
        padding: 16px;
        display: flex;
        overflow-x: scroll;


        .mySwiperContainer {
            margin: 32px 64px 32px 0px;
            width: 450px;
            height: 450px;
            text-align: center;
            color: white;
            font-size: 18px;
            border-radius: 24px;
            box-shadow:
                0px -10px 15px -6px $green,
                0px 5px 15px 0px $blue,
                5px 0px 15px 0px $dark-blue,
                -5px 0px 15px 0px $slightlyLightBlue;
            animation: 3s infinite alternate serviceCardShadow;

            @keyframes serviceCardShadow {
                0% {
                    box-shadow:
                        0px -10px 15px -6px $green,
                        0px 5px 15px 0px $blue,
                        5px 0px 15px 0px $dark-blue,
                        -5px 0px 15px 0px $slightlyLightBlue;
                }

                25% {
                    box-shadow:
                        0px -10px 15px -6px $slightlyLightBlue,
                        0px 5px 15px 0px $green,
                        5px 0px 15px 0px $blue,
                        -5px 0px 15px 0px $dark-blue;
                }

                50% {
                    box-shadow:
                        0px -10px 15px -6px $dark-blue,
                        0px 5px 15px 0px $slightlyLightBlue,
                        5px 0px 15px 0px $green,
                        -5px 0px 15px 0px $blue;
                }

                75% {
                    box-shadow:
                        0px -10px 15px -6px $blue,
                        0px 5px 15px 0px $dark-blue,
                        5px 0px 15px 0px $slightlyLightBlue,
                        -5px 0px 15px 0px $green;
                }

                100% {
                    box-shadow:
                        0px -10px 15px -6px $green,
                        0px 5px 15px 0px $blue,
                        5px 0px 15px 0px $dark-blue,
                        -5px 0px 15px 0px $slightlyLightBlue;
                }
            }

            .mySwiperWebsitesContainer {
                padding: 16px;

                .mySwiperWebsitess {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    flex-wrap: wrap;
                    height: 190px;
                }

                .mySwiperWebsites {
                    margin: 8px;
                    border: 2px solid $white;
                    padding: 8px 16px;
                    border-radius: 8px;
                    height: 48px;

                    a {
                        color: $white;
                        text-decoration: none;

                        p {
                            margin: 0;
                        }
                    }

                }

                .mySwiperWebsitesDescription {
                    padding: 16px;
                    font-size: 16px;
                    letter-spacing: 1px;
                    text-align: left;
                }
            }
        }
    }
}

.mySwiperWebsites:hover {
    background-color: $green;
    border: 2px solid $green !important;
}

.myswiperWrapper::-webkit-scrollbar {
    display: none;
}