@import '../../custom.scss';

@font-face {
    font-family: tourney;
    src: url(../../assets/fonts/Tourney.ttf)
}

.projectsWrapper {
    width: 100%;
    height: auto;
    background-color: $black;
    padding: 48px 0px;

    .projectsBreifContainer {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 48px 0px;

        .projectBreif {
            color: $white;
            display: flex;
            flex-direction: column;
        }

        .projectBreifNumber {
            font-size: 150px;
            letter-spacing: -5px;
            font-family: tourney;
        }

        .projectBreifText {
            margin-top: -40px;
            font-size: 20px;
            opacity: 0.8;
        }
    }

    .showProjectsButtonContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .showProjectsButton {
            background-color: $dark-blue;
            border: none;
            padding: 16px 32px;
            font-size: 18px;
            border-radius: 16px;
        }
    }
}

.showProjectsButton:hover {
    background-color: $blue !important;
}