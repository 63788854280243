@import "../../custom.scss";

.title {
    padding-top: 64px;

    @media (max-width:991.5px) {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }

    p {
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        color: $white;
        text-transform: capitalize;
        text-decoration: underline solid $whiteOpacity 3px;
        text-underline-position: under;
        // text-shadow: 0px 2px 4px $whiteOpacity;

        @media (max-width:300px) {
            font-size: 35px;
        }

    }
}