@import '../../custom.scss';

.footerWrapper {
    padding: 16px 0px;
    background-color: $primary;
    text-align: center;

    p {
        margin: 0;
        color: $white;
    }
}