@import '../../custom.scss';

.socialMediaContainer {
    margin-top: 8px;
    display: flex;
    flex-direction: row;

    .socialMediaIcons {
        color: $white;
        margin-right: 16px;
    }

    .contactSocialMediaIcons {
        color: $white;
        margin-right: 16px;
        border: 1px solid $white;
        padding: 8px;
    }
}