@import '../../custom.scss';

/* From uiverse.io by @satyamchaudharydev */

@media (max-width:992px) {
    .buttonContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.button {
    --width: 100px;
    --height: 25px;
    --tooltip-height: 35px;
    --tooltip-width: 90px;
    --gap-between-tooltip-to-button: calc(var(--tooltip-height) + 3px);
    --button-color: #1163ff;
    --tooltip-color: #fff;
    margin-top: 48px;

    width: 250px;
    height: 50px;
    background: $blue;
    position: relative;
    text-align: center;
    border-radius: 0.45em;
    font-family: "Arial";
    transition: background 0.3s;

    @media (max-width:992px) {
        margin-top: 32px;
    }
}

.button::before {
    position: absolute;
    content: attr(data-tooltip);
    width: var(--tooltip-width);
    height: var(--tooltip-height);
    background-color: $lightBlue;
    font-size: 0.9rem;
    color: $white;
    border-radius: .25em;
    line-height: var(--tooltip-height);
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) + 10px);
    left: calc(50% - var(--tooltip-width) / 2);
}

.button::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: $lightBlue;
    left: calc(50% - 10px);
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) - 10px);
}

.button::after,
.button::before {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
}

.text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
}

.button-wrapper,
.text,
.icon {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    color: #fff;
}

.text {
    top: 0
}

.text,
.icon {
    transition: top 0.5s;
}

.icon {
    color: #fff;
    top: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button:hover {
    background: $dark-blue;
}

.button:hover .text {
    top: -100%;
}

.button:hover .icon {
    top: 0;
}

.button:hover:before,
.button:hover:after {
    opacity: 1;
    visibility: visible;
}

.button:hover:after {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) - 20px);
}

.button:hover:before {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button));
}