@import '../../custom.scss';

.aboutMeWrapper {
    width: 100%;
    height: 100vh;
    background-color: $primary;

    @media (max-width:992px) {
        height: auto;
    }

    .aboutmeContainer {
        width: 100%;
    }

    .aboutmeText {
        padding-top: 32px;

        .aboutmeTextParagraph {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            color: $white;
            opacity: 0.8;

            @media (max-width:1250px) {
                font-size: 18px;
            }

            @media (max-width:1199.5px) {
                font-size: 15px;
            }

            @media (max-width:991.5px) {
                font-size: 20px;
                text-align: left;
            }

            @media (max-width:767.5px) {
                font-size: 18px;
            }

            @media (max-width:540px) {
                font-size: 16px;
            }

            @media (max-width:445px) {
                font-size: 15px;
            }

            @media (max-width:390px) {
                font-size: 14px;
            }

            a {
                color: $green;
            }
        }
    }

    .emailSocialMediaContainer {
        padding-top: 100px;
        padding-bottom: 64px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 450px) {
            padding-top: 32px;
        }

        @media (max-width:991.5px) {
            align-items: flex-start;
            justify-content: flex-start;
        }

        .emailContainer {
            display: flex;
            flex-direction: row;

            p {
                font-style: normal;
                letter-spacing: 0.5px;
                font-weight: 600;
                font-size: 18px;
                line-height: 32px;
                color: $white;

                @media (max-width:327px) {
                    font-size: 14px;
                }
            }
        }


    }
}