@import '../../custom.scss';

.contactUsFieldsWrapper {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .contactUsSuccessMessage {
        width: 100%;
        background-color: green;
        padding: 12px;
        text-align: center;


        p {
            color: white;
            margin: 0;
        }
    }

    .contactUsForm {
        width: 100%;
        padding: 8px;

        .contactUsInputLabel {
            color: $white;
        }

        .contactUsInput {
            background-color: $primary;
            color: $white;
            border-radius: 0px;
        }

        .contactUsSubmitButton {
            width: 100%;
            padding: 12px;
            border-radius: 0px;
            background-color: $blue;
            border: 1px solid $blue;
        }
    }

}