@import '../../custom.scss';

.contactUsWrapper {
    width: 100%;
    background-color: $primary;

    .contactUsContainer {
        padding: 32px 0px;

        .contactUsImageContainer {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width:992px) {
                display: none;
            }

            img {
                width: 60%;

                @media screen and (max-width:1199px) {
                    width: 80%;
                }
            }
        }
    }
}