.profilePictureWrapper {
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;


    @media (max-width:992px) {
        margin-top: 32px;
        height: auto;
        margin-bottom: 32px;
    }


    .profilePicture {
        width: 90%;

        @media screen and (max-width:1200px) {
            width: 110%;
        }

        @media screen and (max-width:992px) {
            width: 85%;
        }

        @media screen and (max-width:500px) {
            width: 100%;
        }
    }

}