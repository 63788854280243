@import "../../custom.scss";

.servicesWrapper {
    width: 100%;
    height: 100vh;
    background-color: $black;

    @media screen and (max-width:992.5px) {
        height: auto;
    }
}


.servicesContainer {
    margin-top: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}