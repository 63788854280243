@import "~bootstrap/scss/bootstrap";
@import "./custom.scss";

@font-face {
    font-family: monserrat;
    src: url(../src/assets/fonts/Montserrat-VariableFont_wght.ttf);
}

* {
    font-family: monserrat;
    box-sizing: border-box;
}

body {
    background-color: $primary
}

body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    background: #000000;
}

body::-webkit-scrollbar-thumb {
    background: $blue;
    border-radius: 100px;
}

body::-webkit-scrollbar-thumb:hover {
    background: $dark-blue;
}