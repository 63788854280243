@import '../../custom.scss';

.skillsListWrapper {
    padding: 48px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .skillContainer {
        padding: 16px;
        color: $white;
        margin: 8px;

        .skillImageContainer {
            width: 100px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .skillNameContainer {
            text-align: center;
            margin: 0;
            padding-top: 8px;
        }
    }
}