@import '../../custom.scss';

.homePageWrapper {
    width: 100%;
    height: 100vh;
    background-color: $primary;

    @media (max-width:992px) {
        height: auto;
    }

    .heroSectionWrapper {
        padding-top: 100px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media (max-width:992px) {
            padding-top: 64px;
            text-align: center;
        }
    }

    .welcomeTypography {
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        color: $white;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);

        @media (max-width:1200px) {
            font-size: 53px;
        }

        @media (max-width:500px) {
            font-size: 46px;
            line-height: 60px;
        }

        @media (max-width:415px) {
            font-size: 40px;
            line-height: 55px;
        }

        @media (max-width:364px) {
            font-size: 34px;
            line-height: 50px;
        }

        @media (max-width:313px) {
            font-size: 30px;
            line-height: 45px;
        }

        span {
            color: $green;
        }
    }

    @media (max-width:992px) {
        .vlContainer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .vl {
        width: 154px;
        height: 5px;
        background: $whiteOpacity;
        box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.5);
    }

    .serviceTypography {
        margin-top: 48px;

        p {
            text-shadow: 5px 4px 4px rgba(0, 0, 0, 0.25);
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 43px;
            color: $offwhite;

            @media (max-width:415px) {
                font-size: 26px;
            }

            @media (max-width:364px) {
                font-size: 22px;
            }

            @media (max-width:313px) {
                font-size: 20px;
            }
        }
    }

    .quoteTypography {
        margin-top: 24px;
        color: $offwhite;
        font-size: 30px;
        opacity: 0.6 !important;
        text-shadow: 5px 4px 4px rgba(0, 0, 0, 0.25);
        font-style: normal;
        font-weight: 600;
        line-height: 43px;
        color: $offwhite;

        @media (max-width:500px) {
            font-size: 26px;
        }

        @media (max-width:415px) {
            font-size: 22px;
        }

        @media (max-width:364px) {
            font-size: 20px;
        }

        @media (max-width:313px) {
            font-size: 18px;
        }
    }
}

.robotLottieContainer {
    height: 76.8vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width:992px) {
        margin-top: 48px;
    }

    @media screen and (max-width:500px) {
        display: none;
    }
}

.lottieFileContainer {
    margin-top: 8px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .lottieFile {
        width: 80px;
    }

    @media (max-width:1200px) {
        margin-top: 31.5px;
    }
}