@import '../../custom.scss';

.serviceCardWrapper {
    padding-left: 16px;
    padding-right: 16px;
    width: 25%;
    height: 60vh;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    box-shadow:
        0px -10px 15px -6px $green,
        0px 5px 15px 0px $blue,
        5px 0px 15px 0px $dark-blue,
        -5px 0px 15px 0px $slightlyLightBlue;
    animation: 3s infinite alternate serviceCardShadow;

    @keyframes serviceCardShadow {
        0% {
            box-shadow:
                0px -10px 15px -6px $green,
                0px 5px 15px 0px $blue,
                5px 0px 15px 0px $dark-blue,
                -5px 0px 15px 0px $slightlyLightBlue;
        }

        25% {
            box-shadow:
                0px -10px 15px -6px $slightlyLightBlue,
                0px 5px 15px 0px $green,
                5px 0px 15px 0px $blue,
                -5px 0px 15px 0px $dark-blue;
        }

        50% {
            box-shadow:
                0px -10px 15px -6px $dark-blue,
                0px 5px 15px 0px $slightlyLightBlue,
                5px 0px 15px 0px $green,
                -5px 0px 15px 0px $blue;
        }

        75% {
            box-shadow:
                0px -10px 15px -6px $blue,
                0px 5px 15px 0px $dark-blue,
                5px 0px 15px 0px $slightlyLightBlue,
                -5px 0px 15px 0px $green;
        }

        100% {
            box-shadow:
                0px -10px 15px -6px $green,
                0px 5px 15px 0px $blue,
                5px 0px 15px 0px $dark-blue,
                -5px 0px 15px 0px $slightlyLightBlue;
        }
    }

    @media screen and (max-width: 1199.5px) {
        width: 30%;
    }

    @media screen and (max-width: 992.5px) {
        width: 40%;
        margin-top: 32px;
        margin-bottom: 48px;

    }

    @media screen and (max-width: 767.5px) {
        width: 55%;
    }

    @media screen and (max-width: 500.5px) {
        width: 85%;
    }

    @media screen and (max-width: 400.5px) {
        width: 92%;
    }

}

.serviceCardTitleContainer {
    text-align: center;


    h2 {
        color: $white;
        font-weight: 600;
    }
}

.featuresCardDescriptionContainer {
    text-align: justify;
    letter-spacing: 0.5px;
    color: $white;
    opacity: 0.8;

    @media (max-width: 350px) {
        font-size: 14px;
    }
}