$primary : #000;
$white: #fff;
$whiteOpacity: rgba(255, 255, 255, 0.5);
$offwhite: #D8F3FF;
$secondoffwhite: #EFF5F8;
$black: #000;
$green: #00C9C8;
$slightlyLightBlue: #3C8AB8;
$lightBlue: #849FBC;
$blue: #4444dd;
$dark-blue: #1e1e69;
$lightyellow: #F9F871;
$orange: #fca311;
$success: #4bb543;